import React, { useEffect, useInsertionEffect, useRef, useState } from "react";

import styled from "styled-components";
import _ from "lodash";

import { SUBSCRIBE_REAL_SIGN } from "../../../graphql/itemInfo";
import Users from "../../RealTimeRequest/user";

import ProfitStatus from "./ContentPresenter";
import { mainClient } from "../../../../apollo";
import { SUBSCRIBE_CURRENTSTATE } from "../../../graphql/manageUser";

const Container = styled.div`
  .toolbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
`;

const ContentContainer = () => {
  const [usersData, setUsersData] = useState([]);
  const [sumData, setSumData] = useState({
    liveProfitNLoss: 0,
    totValuationGNL: 0,
    profitNLoss: 0,
    totalFee: 0,
    liveUserBalance: 0,
  });

  useEffect(() => {
    const currentUsers = new Users();
    const initData = currentUsers.initialize();

    setSumData(initData.sum);
    setUsersData(initData.users);
  }, []);

  useEffect(() => {
    // currentState
    const subscribeCurrentState = mainClient
      .subscribe({
        query: SUBSCRIBE_CURRENTSTATE,
        fetchPolicy: "no-cache",
      })
      .subscribe(() => {
        const currentUsers = new Users();
        const initData = currentUsers.initialize();

        setSumData(initData.sum);
        setUsersData(initData.users);
      });
    // 체결데이터 변경시
    const subscribeSign = mainClient
      .subscribe({
        query: SUBSCRIBE_REAL_SIGN,
        fetchPolicy: "no-cache",
      })
      .subscribe(async ({ data: { subscribeRealSign: sign } }) => {
        const currentUsers = new Users();
        const { users, sum } = currentUsers.initialize();

        setSumData(sum);
        setUsersData(users);
      });

    return () => {
      subscribeSign.unsubscribe();
      subscribeCurrentState.unsubscribe();
    };
  }, [usersData, sumData]);

  return (
    <Container>
      <ProfitStatus data={usersData} sum={sumData} />
    </Container>
  );
};

export default ContentContainer;
