import React, { useEffect } from "react";

import { ApolloProvider } from "@apollo/client";
import Router from "./Router";

import { mainClient } from "./apollo";
import GlobalStyle from "./GlobalStyle";
import "antd/dist/reset.css";
function App() {
  // useEffect(() => {
  //   // 창이 닫힐 때 실행될 핸들러
  //   const handleBeforeUnload = () => {
  //     localStorage.removeItem("userId"); // 특정 항목만 제거
  //     // 또는
  //     localStorage.clear(); // 모든 항목 제거
  //   };

  //   // 이벤트 리스너 등록
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // cleanup 함수
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  return (
    <div className="App">
      <ApolloProvider client={mainClient}>
        <GlobalStyle />
        <Router />
      </ApolloProvider>
    </div>
  );
}

export default App;
