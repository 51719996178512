﻿import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

// HTTP 연결
const httpLink = new HttpLink({
  uri: `https://${process.env.GRAPHQLSERVER}/graphql`,
});

// WebSocket 연결 (새로운 방식)
const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${process.env.GRAPHQLSERVER}/subscriptions`,
    retryAttempts: 3,
    shouldRetry: () => true,
    connectionParams: {
      userId: localStorage.getItem("userId"),
    },
  })
);

// HTTP와 WebSocket 링크 분리
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const mainClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});
