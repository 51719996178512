import moment from "moment";
import { mainClient } from "../../apollo";

import { WRITE_MEMBER_ID, USER_DATA, CURRENT_STATE } from "./userDataGql";
export const writeUserData = (data) => {
  const testMembers = [];
  const regularMembers = [];
  const todayMembers = [];
  const today = moment();
  const referenceTime = moment().set({ hour: 7, minute: 40 });

  let todayDateId;

  if (today.isBefore(referenceTime)) {
    todayDateId = parseInt(today.subtract(1, "days").format("YYYYMMDD"));
  } else {
    todayDateId = parseInt(today.format("YYYYMMDD"));
  }

  data.forEach((user) => {
    // 모의투자
    if (user.authority === 4) testMembers.push(user.id);

    // 정회원
    if (user.authority === 1 || user.authority === 0) {
      regularMembers.push(user.id);
    }

    if (
      (user.user_balance_history[0] &&
        user.user_balance_history[0].dateId === todayDateId) ||
      user.position.length > 0 ||
      user.userBalance > 0
    ) {
      todayMembers.push(user.user_balance_history[0].userId);
    }

    mainClient.writeFragment({
      id: `USERDATA:${user.id}`,
      fragment: USER_DATA,
      data: { ...user, __typename: "UserData" },
    });
  });

  mainClient.writeFragment({
    id: "USERS_ID:tester",
    fragment: WRITE_MEMBER_ID,
    data: { users: testMembers, __typename: "UserList" },
  });
  mainClient.writeFragment({
    id: "USERS_ID:regular",
    fragment: WRITE_MEMBER_ID,
    data: { users: regularMembers, __typename: "UserList" },
  });

  mainClient.writeFragment({
    id: "USERS_ID:today",
    fragment: WRITE_MEMBER_ID,
    data: { users: todayMembers, __typename: "UserList" },
  });
  return true;
};

export const getUserDataById = (id) => {
  return (newPosition = mainClient.readFragment({
    id: "USERS_ID:tester",
    fragment: WRITE_MEMBER_ID,
    data,
  }));
};

// type :0 (모의투자) 1: (정회원, 준회원) 2: (오늘날짜) 3: 모든회원
export const getUserData = (type) => {
  const users = [];
  let usersList = [];
  // 모의투자
  const testMembers = mainClient.readFragment({
    id: "USERS_ID:tester",
    fragment: WRITE_MEMBER_ID,
  });

  // 정회원 준회원
  const regulars = mainClient.readFragment({
    id: "USERS_ID:regular",
    fragment: WRITE_MEMBER_ID,
  });

  usersList = testMembers.users;

  if (type > 0) {
    usersList = usersList.concat(regulars.users);
  }

  usersList.forEach((user) => {
    users.push(
      mainClient.readFragment({
        id: `USERDATA:${user}`,
        fragment: USER_DATA,
      })
    );
  });

  return users;
};

export const handleUserCurrentState = (data) => {
  const currentUser = data.id;
  const newData = {
    position: data.position,
    user_balance_history: data.user_balance_history,
  };

  // isOverForeign, isOverDomestic, isOverOption, isOverHangSeng 속성이 있는 경우에만 추가, 없으면 null
  ["isOverForeign", "isOverDomestic", "isOverOption", "isOverHangSeng"].forEach(
    (key) => {
      if (data[key] !== undefined) newData[key] = data[key];
      else newData[key] = null;
    }
  );

  mainClient.writeFragment({
    id: `USERDATA:${currentUser}`,
    fragment: CURRENT_STATE,
    data: { __typename: "UserData", ...newData },
    overwrite: true,
  });

  const todayUsers = mainClient.readFragment({
    id: "USERS_ID:today",
    fragment: WRITE_MEMBER_ID,
  });
  if (todayUsers.users.indexOf(currentUser) === -1) {
    mainClient.writeFragment({
      id: "USERS_ID:today",
      fragment: WRITE_MEMBER_ID,
      data: { users: [...todayUsers.users, currentUser] },
    });
  }
};

export const getUserStateData = () => {
  const result = [];
  const todayUsersData = mainClient.readFragment({
    id: "USERS_ID:today",
    fragment: WRITE_MEMBER_ID,
  });

  todayUsersData.users.forEach((id) => {
    const data = mainClient.readFragment({
      id: `USERDATA:${id}`,
      fragment: USER_DATA,
    });

    result.push(data);
  });

  return result;
};
