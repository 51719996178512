import React from "react";
import styled from "styled-components";
import { Button, Card, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { MANGER_LOGIN } from "../graphql/manageUser";
import { useMutation } from "@apollo/client";
import { useUserDispatch } from "../../context";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  height: 100vh;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  form {
    margin-top: 24px;
  }
`;

const LoginContainer = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const managerId = Form.useWatch("id", form);
  const dispatch = useUserDispatch();

  const [login] = useMutation(MANGER_LOGIN, {
    fetchPolicy: "no-cache",

    onCompleted: ({ managerLogin }) => {
      if (managerLogin === 200) {
        localStorage.setItem("userId", managerId);

        dispatch({ type: "LOGIN", data: { userId: managerId } });
        navigate("/dashboard/main");
      } else {
        alert("로그인에 실패하였습니다");
      }
    },
    onError: (error) => {
      console.log("loginError", error);
    },
  });

  const onFinish = (values) => {
    login({
      variables: values,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Container>
      <Card>
        <Form
          name="login"
          style={{
            width: 400,
          }}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            name="id"
            rules={[{ required: true, message: "아이디를 입력해주세요" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="아이디 입력"
            />
          </Form.Item>

          <Form.Item
            name="userPw"
            rules={[{ required: true, message: "비밀번호를 입력해주세요" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="비밀번호 입력"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block size="large">
              로그인
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Container>
  );
};

export default LoginContainer;
